import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable }  from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map, mergeMap, timeout, delay } from 'rxjs/operators';

@Injectable()
export class GlobalService {
    apiUrl: string;
    confirmClass: string;
    blockUI: boolean = false;
    localeId: any;

    dialogTitle = '';
    dialogVisible = false;
    dialogMessage = '';
    dialogClass = 'popup';
    dialogIcon = '';
    dialogWidth = 500;
    dialogHeight = 150;

    device = 'desktop';
    config;

    constructor(public http: HttpClient, public router: Router) {
        if(window.innerWidth < 700){
            this.dialogWidth = 300;
            this.dialogHeight = 300;
            this.device = 'mobile';
        }
    }

    readConfig() {
        return new Observable(observer => {
            if (this.apiUrl == undefined) {
                this.http.get('./assets/config.json')
                .subscribe((config: any) => {
                    this.config = config;
                    this.apiUrl = location.origin + '/dmz/';
                    // this.apiUrl = "http://localhost:5500/dmz/";
                    // this.apiUrl = "https://192.168.123.24/dmz/"; 
                    // this.apiUrl = "https://172.24.1.107/dmz/";
                    // this.apiUrl = "https://transport.sby.wingscorp.com/dmz/";
                    // this.apiUrl = "https://api.wingssurya.com/e-logistics-api/";
                    this.apiUrl = this.config["api"];
                    sessionStorage.setItem('apiUrl', JSON.stringify(this.apiUrl));
                    observer.next(true);
                    observer.complete();
                }, err => {
                    observer.error(err);
                });
            } else {
                observer.next(true);
                observer.complete();
            }
        });
    }

    get(url: string) {
        return this.readConfig()
            .pipe(
                mergeMap(() => {
                    var headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('auth.token')}`
                        // 'Authorization': `${localStorage.getItem('auth.token')}`
                    });
                    return this.http.get(this.apiUrl + url, {headers: headers, withCredentials: true});
                })
            )
            .pipe(
                map((result: any) => {
                    if(result == null){
                        this.showErrorDialog("Login expired. Silahkan login lagi.");
                        this.router.navigateByUrl('/login');
                        this.blockUI = false;
                    }
                    else if (result.result) {
                        return result.rows;
                    } else {
                        this.showErrorDialog(result.message);
                        if (result.message === 'Login is required') {
                            this.router.navigateByUrl('/login');
                            this.blockUI = false;
                        }

                        throw new Error(result.message);
                    }
                })
            )
            .pipe(
                catchError(
                    (error: any, caught: Observable<any>) => {
                        if (error.status === 401) {
                            this.showErrorDialog("Silahkan login terlebih dahulu!");
                            this.router.navigateByUrl('/login');
                            this.blockUI = false;
                        }
                        throw error;
                    }
                ),
            );
    }

    post(url: string, body: any) {
        return this.readConfig()
            .pipe(
                mergeMap(() => {
                    var headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('auth.token')}`
                        // 'Authorization': `${localStorage.getItem('auth.token')}`
                    });
                    return this.http.post(this.apiUrl + url, body, {headers: headers, withCredentials: true});
                })
            )
            .pipe(
                map((result: any) => {
                    if(result == null){
                        this.showErrorDialog("Login expired. Silahkan login lagi.");
                        this.router.navigateByUrl('/login');
                        this.blockUI = false;
                    }
                    else if (result.result) {
                        return result.rows;
                    } else {
                        this.showErrorDialog(result.message);
                        if (result.message === 'Login is required') {
                            this.router.navigateByUrl('/login');
                            this.blockUI = false;
                        }

                        throw new Error(result.message);
                    }
                })
            )
            .pipe(
                catchError(
                    (error: any, caught: Observable<any>) => {
                        if (error.status === 401) {
                            this.showErrorDialog("Silahkan login terlebih dahulu!");
                            this.router.navigateByUrl('/login');
                            this.blockUI = false;
                        }
                        throw error;
                    }
                ),
            );
    }

    postNotification(url: string, body: any) {
        return this.readConfig()
            .pipe(
                mergeMap(() => {
                    var headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('auth.token')}`
                        // 'Authorization': `${localStorage.getItem('auth.token')}`
                    });
                    return this.http.post(this.apiUrl + url, body, {headers: headers, withCredentials: true});
                })
            )
            .pipe(
                map((result: any) => {
                    if(!result.success){
                        this.showErrorDialog("Login expired. Silahkan login lagi.");
                        this.router.navigateByUrl('/login');
                        this.blockUI = false;
                    }
                    else if (result.success) {
                        return result.rows;
                    }
                })
            )
            .pipe(
                catchError(
                    (error: any, caught: Observable<any>) => {
                        if (error.status === 401) {
                            this.showErrorDialog("Silahkan login terlebih dahulu!");
                            this.router.navigateByUrl('/login');
                            this.blockUI = false;
                        }
                        throw error;
                    }
                ),
            );
    }

    postSAP(url: string, body: any) {
        return new Observable(observer => {
            this.readConfig()
            .pipe(
                mergeMap(() => {
                var headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('auth.token')}`
                        // 'Authorization': `${localStorage.getItem('auth.token')}`
                    });
                    return this.http.post(this.apiUrl + url, body, {headers: headers, withCredentials: true}).pipe(timeout(300000));
                })
            )
            .subscribe((result: any) => {
                if(result != null){
                    observer.next(result);
                    observer.complete();
                }else{
                    this.showErrorDialog("Login expired. Silahkan login lagi.");
                    this.router.navigateByUrl('/login');
                    this.blockUI = false;
                }
            }, (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.showErrorDialog("Silahkan login terlebih dahulu!");
                    this.router.navigateByUrl('/login');
                    this.blockUI = false;
                }
                throw error;
            });
        });
    }

    toggleBlockUI() {
        this.blockUI = !this.blockUI;
    }

    showErrorDialog(message: string){
        this.dialogTitle = 'Error Message';
        this.dialogMessage = message;
        this.dialogClass = 'popup error-message';
        this.dialogIcon = 'warning';
        this.dialogVisible = true;
    }

    showSuccessDialog(message: string){
        this.dialogTitle = 'Success Message';
        this.dialogMessage = message;
        this.dialogClass = 'popup success-message';
        this.dialogIcon = 'check_circle';
        this.dialogVisible = true;
    }

    RemoveZero(param: string){
        let start = 0;
        for(let i=0; i<param.length; i++){
            if(param[i] == '0'){
                start++;
            }else{
                break;
            }
        }

        let returnValue = param.substring(start, param.length);
        return returnValue;
    }

    AddZero(param: string, length: number) {
        let add = '';
        for(let i=0; i<(length-param.length); i++){
            add += '0';
        }

        let returnValue = add + param;
        return returnValue;
    }

    getDb2DateTime(date: string, time: string){
        if(date == "0000-00-00"){
            return "";
        }

        let d = date.substr(0,4) + "-" + date.substr(5,2) + "-" + date.substr(8,2);
        let t = time.substr(0,2) + ":" + time.substr(3,2) + ":" + time.substr(6,2);

        return new Date(d + " " + t);
    }

    formatSAPDate(date: Date, separate: boolean){
        if(separate)
            return ("0" + date.getDate()).slice(-2) + '.' + ("0" + (date.getMonth()+1)).slice(-2) + '.' + date.getFullYear();
        else
            return date.getFullYear() + ("0" + (date.getMonth()+1)).slice(-2) + ("0" + date.getDate()).slice(-2);
    }

    formatSAPTime(time: Date, separate: boolean){
        if(separate)
            return ("0" + time.getHours()).slice(-2) + ':' + ("0" + time.getMinutes()).slice(-2) + ":" + ("0" + time.getSeconds()).slice(-2);
        else
            return ("0" + time.getHours()).slice(-2) + ("0" + time.getMinutes()).slice(-2) + ("0" + time.getSeconds()).slice(-2);
    }

    replaceAll(fullString: string, find: string, replace: string){
        for(var i = 0; i < 10; i++){
            fullString = fullString.replace(find, replace);
        }
        return fullString;
    }

    formatDate(date: Date, format: string){
        var formatted = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        
        if(format == 'dd.mm.yyyy')
        {
            formatted = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
        }else if(format == 'dd-mm-yyyy')
        {
            formatted = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
        }else if(format == 'dd/mm/yyyy')
        {
            formatted = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
        }else if(format == 'mm/dd/yyyy')
        {
            formatted = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
        }else if(format == 'ddmmyyyy')
        {
            formatted = ('0' + date.getDate()).slice(-2) + ('0' + (date.getMonth() + 1)).slice(-2) + date.getFullYear();
        }else if(format == 'yyyy.mm.dd')
        {
            formatted = date.getFullYear() + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + ('0' + date.getDate()).slice(-2);
        }else if(format == 'yyyy-mm-dd')
        {
            formatted = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        }else if(format == 'yyyy/mm/dd')
        {
            formatted = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
        }else if(format == 'yyyymmdd')
        {
            formatted = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2);
        }else if(format == 'HHmmss')
        {
            formatted = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2);
        }else if(format == 'HH:mm:ss')
        {
            formatted = ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
        }else if(format == 'dd_MM_yyyy_HHmmss')
        {
            formatted = ('0' + date.getDate()).slice(-2) + '_' + ('0' + (date.getMonth() + 1)).slice(-2) + '_' + date.getFullYear() + "_" + ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2);
        }
        return formatted;
    }

    getLastDateOfMonth(year, month){
        var date = new Date(year, (month+1), 0);

        return date.getDate();
    }

    sortArrayOfObjectAsc(array: any[], property: string){
        array.sort(function(a, b) {
          if (a[property] < b[property]) return -1;
          if (a[property] > b[property]) return 1;
          return 0;
        });

        return array;
    }

    sortArrayOfObjectDesc(array: any[], property: string){
        array.sort(function(a, b) {
          if (b[property] < a[property]) return -1;
          if (b[property] > a[property]) return 1;
          return 0;
        });

        return array;
    }

    getGMT7() {
        var date = new Date();
        var offset = date.getTimezoneOffset()/60*-1;
        
        var diff = offset - 7;
        
        date.setHours(date.getHours() - diff);
        
        return date;
    }
}
import { Injectable } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { AuthorizationObject } from '../models/authorization-object';
import { UserObject } from '../models/user-object';
import { User } from '../models/user';
import { UserGroup } from '../models/user-group';
import { Application } from '../models/application';
import { Menu } from '../models/menu';
import { MenuItem } from '../models/menu-item';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
    isLoggedIn:boolean = false;
    isSidebarVisible:boolean = true;
    isTopbarVisible:boolean = true;
    redirectUrl:string = '';
    currentAppUrl:string = '';
    currentApp:Application;
    user:User;    
    menu: MenuItem[];

    constructor(private global:GlobalService){
        this.loadSession();
        if(this.isLoggedIn){
            this.menu = this.generateMenuItem('e-logistics');

            //check if Marketing, if true = hide menu 'Input Order'
            if(this.user.groups != undefined){
                var isMarketing = this.user.groups.find(grup => grup.groupCode.includes('Marketing'));
                if(isMarketing != undefined){
                    this.menu = this.menu.filter(menu => menu.text != "Input Order");
                }
            }
        }
    }

    login(userName:string, password:string):Observable<boolean> {
        let subject:Subject<boolean> = new Subject<boolean>();
        this.global.post('auth/login', {
            userName: userName,
            password: password
        })
        .pipe(
            mergeMap(rows => {
                if(rows['token'] != undefined && rows['token'] != null){
                    localStorage.setItem('auth.token', rows["token"]);
                    this.isLoggedIn = true;
                }

                return this.global.get(`user/detail/${userName}`);
            })
        )
        .pipe(
        	mergeMap(rows => {
	            this.user = <User>rows[0];

	            return this.global.get(`user/${this.user.userName}/group-list`);
	        })
	    )
        .pipe(
        	mergeMap(rows => {
	            this.user.groups = <UserGroup[]>rows;            
	            
	            return this.global.get(`user/${this.user.userName}/app-list`);
	        })
	    )
        .pipe(
        	mergeMap(rows => {
	            this.user.apps = <Application[]>rows;            
	            
	            return this.global.get(`user/${this.user.userName}/menu-list`);
	        })
	    )
        .pipe(
        	mergeMap(rows => {
	            let arrAction:string[];
	            let arrAction2:string[];

	            this.user.menus = {}

                let tremonMenu = {
                    action: 'ADD,EDIT,DELETE',
                    appUrl: 'e-logistics',
                    isPublic: false,
                    menuDesc: 'Trexmon',
                    menuIcon: 'exit_to_app',
                    menuUrl: '/',
                    orderNo: 1000,
                    parentUrl: '',
                    externalAppUrl: 'https://trexmon.wingssurya.com/',
                    menuType: 'external-link'
                }
                rows.push(tremonMenu);

	            rows.forEach(row => {                
	                let menu:Menu = <Menu>row;
	                
	                arrAction2 = [];
	                arrAction = menu.action.split(',');
	                arrAction.forEach(action => {
	                    if(arrAction2.indexOf(action) == -1) arrAction2.push(action);
	                })                
	                menu.action = arrAction2.join(',');

	                if(this.user.menus[menu.appUrl] == undefined){
	                    this.user.menus[menu.appUrl] = {};
	                }
	                this.user.menus[menu.appUrl][menu.menuUrl] = menu;
	            });

	            return this.global.get(`user/${this.user.userName}/auth-obj-list`);
	        })
	    )
        .pipe(
        	mergeMap(rows => {
	            this.user.authObjects = {};
	            rows.forEach(row => {            
	                let authObj:AuthorizationObject = <AuthorizationObject>row;    
	                
	                if(this.user.authObjects[authObj.appUrl] == undefined){
	                    this.user.authObjects[authObj.appUrl] = {};
	                }
	                if(this.user.authObjects[authObj.appUrl][authObj.objCode] == undefined){
	                    this.user.authObjects[authObj.appUrl][authObj.objCode] = [];
	                }
	                this.user.authObjects[authObj.appUrl][authObj.objCode].push(authObj);
	            });

	            this.saveSession();

	            let appUrlList = "";
	            let counter = 0;
	            for(let app of this.user.apps){
	                if(counter == 0){
	                    appUrlList = appUrlList + "'" + app.appUrl + "'";    
	                }else{
	                    appUrlList = appUrlList + ",'" + app.appUrl + "'";
	                }
	                counter++;
                }
                // appUrlList = "'e-logistics'";

	            return this.global.post(`user/global-auth-obj-list`, appUrlList);
	        })
	    )
        .pipe(
        	mergeMap(rows => {
	            rows.forEach(row => {            
	                let authObj:AuthorizationObject = <AuthorizationObject>row;    
	                
	                if(this.user.authObjects[authObj.appUrl] == undefined){
	                    this.user.authObjects[authObj.appUrl] = {};
	                }
	                if(this.user.authObjects[authObj.appUrl][authObj.objCode] == undefined){
	                    this.user.authObjects[authObj.appUrl][authObj.objCode] = [];
	                }
	                this.user.authObjects[authObj.appUrl][authObj.objCode].push(authObj);
	            });

	            this.saveSession();

	            return this.global.get(`user/${this.user.userName}/user-obj-list`);
	        })
	    )
        .subscribe(rows => {
            this.user.userObjects = {};
            rows.forEach(row => {            
                let userObj:UserObject = <UserObject>row;    
                
                if(this.user.userObjects[userObj.appUrl] == undefined){
                    this.user.userObjects[userObj.appUrl] = {};
                }
                if(this.user.userObjects[userObj.appUrl][userObj.objCode] == undefined){
                    this.user.userObjects[userObj.appUrl][userObj.objCode] = [];
                }
                this.user.userObjects[userObj.appUrl][userObj.objCode].push(userObj);
            });

            this.menu = this.generateMenuItem('e-logistics');

            //check if Marketing, if true = hide menu 'Input Order'
            var isMarketing = this.user.groups.find(grup => grup.groupCode.includes('Marketing'));
            if(isMarketing != undefined){
                this.menu = this.menu.filter(menu => menu.text != "Input Order");
            }

            this.saveSession();

            subject.next(true);
        }, err => {
            subject.next(false);
        });
        
        return subject.asObservable();
    }

    saveSession(){
        localStorage.setItem('auth.user', JSON.stringify(this.user));
        localStorage.setItem('auth.isLoggedIn', String(this.isLoggedIn));
        localStorage.setItem('auth.isSidebarVisible', String(this.isSidebarVisible));
        localStorage.setItem('auth.redirectUrl', this.redirectUrl);
        localStorage.setItem('auth.currentAppUrl', this.currentAppUrl);
        localStorage.setItem('auth.currentApp', JSON.stringify(this.currentApp));    
    }

    loadSession(){
        if(localStorage.getItem('auth.user') != 'undefined') this.user = JSON.parse(localStorage.getItem('auth.user'));
        if(localStorage.getItem('auth.isLoggedIn') != 'undefined') this.isLoggedIn = Boolean(localStorage.getItem('auth.isLoggedIn'));
        if(localStorage.getItem('auth.isSidebarVisible') != 'undefined') this.isSidebarVisible = Boolean(localStorage.getItem('auth.isSidebarVisible'));
        if(localStorage.getItem('auth.redirectUrl') != 'undefined') this.redirectUrl = localStorage.getItem('auth.redirectUrl');
        if(localStorage.getItem('auth.currentAppUrl') != 'undefined') this.currentAppUrl = localStorage.getItem('auth.currentAppUrl');
        if(localStorage.getItem('auth.currentApp') != 'undefined') this.currentApp = JSON.parse(localStorage.getItem('auth.currentApp'));
    }

    logout(){        
        this.user = null;
        this.isLoggedIn = false;
        this.isSidebarVisible = false;
        this.redirectUrl = "/login";
        this.currentAppUrl = "";
        this.currentApp = null;
        localStorage.clear();
        sessionStorage.clear();        
        
        // this.global.get('api/user/public/logout')
        // 	.subscribe(rows => {
        // });
    }

    generateMenuItem(appUrl:string):MenuItem[]{
        let dictMenu = this.user.menus[appUrl];
        let result:MenuItem[];

        result = this.generateSubMenuItem(dictMenu, '');

        return result;
    }

    private generateSubMenuItem(dictMenu:any, parentUrl:string):MenuItem[]{
        let result:MenuItem[] = [];
        let item:MenuItem;

        for(let menuUrl in dictMenu){        
            if(dictMenu[menuUrl].parentUrl == parentUrl){
                item = {
                    text: dictMenu[menuUrl].menuDesc,
                    icon: dictMenu[menuUrl].menuIcon,
                    expanded: false,
                    items: this.generateSubMenuItem(dictMenu, dictMenu[menuUrl].menuUrl),
                    menuType: '',
                    externalAppUrl: ''                
                };

                if(item.items.length == 0){
                    item.routerLink = dictMenu[menuUrl].menuUrl;
                    item.menuType = dictMenu[menuUrl].menuType;
                    item.externalAppUrl = dictMenu[menuUrl].externalAppUrl;
                    delete item.items;
                }

                result.push(item);
            }
        }

        return result;
    }

    like(str, expr) {
	    expr = expr.toLowerCase(); // ignoring locale for now
	    expr = expr.replace(".", "\\."); // "\\" is escaped to "\" (thanks, Alan M)
	    // ... escape any other potentially problematic characters here
	    expr = expr.replace("?", ".");
	    expr = expr.replace("%", ".*");
	    str = str.toLowerCase().trim();
	    return str.search(expr) == -1 ? false : true;
	}

    isObjectAllowed(appl, objc, acti, objv):Observable<boolean>{
        let subject:Subject<boolean> = new Subject<boolean>();
        if(objv == "" || objv.trim() == "") subject.next(true);

        var param = {
            I_APPL: appl,
            I_OBJC: objc,
            I_ACTI: acti,
            I_OBJV: objv
        }

        this.global.postSAP('general/get-lr-obj', param)
        .subscribe(row => {
            var data = row["T_LR_OBJ"];
            if(data.length == 0 ){
                subject.next(false);
            }else{
                for(var i = 0; i < data.length; i++){
                    if(this.like(data[i]["VAL1"],objv)){
                        subject.next(true);
                    }
                }
            }
        });

        return subject.asObservable();
    }

    permission(type,url?){
        let isExist = false;
        let menuContent = this.user.menus['e-logistics'][url];
        if(menuContent!=undefined){
            isExist = menuContent.action.includes(type);
        }
        return isExist;
    }
}
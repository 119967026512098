import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Component } from '@angular/core';

import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { GlobalService } from './services/global.service';
import { PushNotificationsService } from './services/push-notification.service';
import { AppRoutingModule } from './/app-routing.module';

import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ApkLinkComponent } from './apk-link/apk-link.component';
import { ApkLinkInternalComponent } from './apk-link-internal/apk-link-internal.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import config from 'devextreme/core/config';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

config({decimalSeparator: ',', thousandsSeparator : '.'})
registerLocaleData(localeDe, 'de-DE', localeDeExtra);


@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    HomeComponent,
    LoginComponent,
    ApkLinkComponent,
    ApkLinkInternalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    SharedModule
  ],
  providers: [AuthService, GlobalService, AuthGuardService, PushNotificationsService],
  bootstrap: [AppComponent]
})
export class AppModule { }

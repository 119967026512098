import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent{
	version = '103';
	checkVersion = false;
	data: any = {username: '', password: ''};
	showNotification = false;
	notificationMessage = '';
	downloadLink = 'https://transport.sby.wingscorp.com/apk/wtp-transport.apk';
	// downloadLink = 'https://192.168.123.24/apk/wtp-192.apk';


	constructor(private router:Router, public auth: AuthService, public global: GlobalService, private http: HttpClient){
		if(this.checkVersion == true){
			this.global.toggleBlockUI();
			var objList = [{OBJC: "VERSION_APK_WTP"}];
		    var param = { "I_T_LR_OBJ" : objList };
		    this.global.postSAP('general/get-trexplan-obj', param)
		      .subscribe(result => {
		        var newestVersion = result["E_T_LR_OBJ"][0];
		        if(newestVersion.VAL1 > this.version){
		        	this.showNotification = true;
		        	this.notificationMessage = "Versi terbaru APK sudah tersedia (v." + newestVersion.VAL1 + ")!";
		        }
		        this.global.toggleBlockUI();
		    });
		}
	}

	ngOnInit(){
		this.auth.isSidebarVisible = false;
		if(this.auth.isLoggedIn){
	    	this.router.navigate(['/']);
	    }
	}

	login(){
	    this.global.toggleBlockUI();
	    this.auth.login(this.data.username, this.data.password)
	      .subscribe(result => {
	        if(result) {
	        	if(this.auth.user.userObjects["e-logistics"] != undefined){
		        	if(this.auth.user.userObjects["e-logistics"].LIFNR == undefined){
			        	this.router.navigateByUrl('/');
			        }else{
			        	this.router.navigateByUrl('/vendor/welcome-page');
			        }
			    }else{
			    	this.router.navigateByUrl('/');
			    }
	        	this.global.toggleBlockUI();
	        }
	        else {
	          this.global.showErrorDialog('User atau password anda salah!');
	          this.global.blockUI = false;
	        }
	    });  
	}

	closePopup(){
		this.showNotification = false;
	}
}
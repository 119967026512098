import { Component, ViewChild } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'apk-link',
  templateUrl: './apk-link.component.html'
})
export class ApkLinkComponent{
	apk192 = '';
	apkTransport = '';
	panduanWeb = '';
	panduanWebLP = '';
	version = "";

	link:any[] = [];

  	constructor(public global: GlobalService, public auth: AuthService) {		
		this.link = this.link.concat(this.auth.user.authObjects["e-logistics"].LINK_WTP_external.filter(dt => dt.val3 == 'NEW'));
		this.link = this.link.concat(this.auth.user.authObjects["e-logistics"].LINK_WTP_external.filter(dt => dt.val3 == ''));
	}
}
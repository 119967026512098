import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ApkLinkComponent } from './apk-link/apk-link.component';
import { ApkLinkInternalComponent } from './apk-link-internal/apk-link-internal.component';


const routes:Routes = [
    { 
        path: '', 
        component: HomeComponent,
        children: [            
            {
              path: 'login',
              component: LoginComponent
            },            
            {
              path: 'apk-link',
              component: ApkLinkComponent
            },         
            {
              path: 'apk-link-internal',
              component: ApkLinkInternalComponent
            },
            {
              path: 'vendor',
              loadChildren:'./vendor/vendor.module#VendorModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'security',
              loadChildren:'./security/security.module#SecurityModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'depo',
              loadChildren:'./depo/depo.module#DepoModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'security-depo',
              loadChildren:'./security-depo/security-depo.module#SecurityDepoModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'b2b',
              loadChildren:'./b2b/b2b.module#B2bModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'transplan',
              loadChildren:'./transplan/transplan.module#TransplanModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'frozen',
              loadChildren:'./frozen/frozen.module#FrozenModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'driver',
              loadChildren:'./driver/driver.module#DriverModule',
              canActivate: [AuthGuardService] 
            },
            {
              path: 'plant',
              loadChildren:'./plant/plant.module#PlantModule',
              canActivate: [AuthGuardService] 
            }
        ]
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports:[
    RouterModule
  ],
  declarations: [],
  providers: [    
  ]
})
export class AppRoutingModule { }
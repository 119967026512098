import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutofocusDirective } from './directives/autofocus.directive';

import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxResponsiveBoxModule } from 'devextreme-angular/ui/responsive-box';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { MatSidenavModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxLinearGaugeModule } from 'devextreme-angular/ui/linear-gauge';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxRadioGroupModule, DxProgressBarModule } from 'devextreme-angular';
import { DxRadioGroupComponent } from 'devextreme-angular';
import { DxTemplateModule } from 'devextreme-angular';
import { DxPivotGridModule } from 'devextreme-angular';
import { DxTabPanelModule } from 'devextreme-angular';
import { DxValidatorModule } from 'devextreme-angular';
import { DxValidationSummaryModule } from 'devextreme-angular';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
	imports: [
		CommonModule
	],
    declarations: [
      	AutofocusDirective
    ],
    exports: [
      	AutofocusDirective,
		DxButtonModule,
	    DxResponsiveBoxModule,
	    DxSelectBoxModule,
	    MatSidenavModule,
	    MatToolbarModule,
	    MatListModule,
	    MatMenuModule,
	    MatIconModule,
	    DxTreeViewModule,
	    DxFormModule,
	    DxTextBoxModule,
	    DxLoadPanelModule,
	    DxPopupModule,
	    DxDataGridModule,
	    DxDropDownBoxModule,
	    DxDateBoxModule,
	    DxListModule,
	    DxLinearGaugeModule,
	    DxNumberBoxModule,
	    DxPieChartModule,
	    DxScrollViewModule,
	    DxTagBoxModule,
	    DxChartModule,
	    DxCheckBoxModule,
	    DxTextAreaModule,
	    DxTabsModule,
	    BarecodeScannerLivestreamModule,
		DxFileUploaderModule,
		DxRadioGroupModule,
		DxTemplateModule,
		DxPivotGridModule,
		DxProgressBarModule,
		DxTabPanelModule,
		DxValidatorModule,
		DxValidationSummaryModule,
		QRCodeModule
    ],
    providers: []
})
export class SharedModule{}

import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';
import { MenuItem } from '../models/menu-item';
import * as shajs from 'sha.js';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html'
})
export class TopBarComponent implements OnDestroy{
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;

	show: any = {};
	dataUser: any = {};
	menuOpen: boolean = true;
	menuList: any = [];
	version = '96';

	// private snav: ElementRef;
	// @ViewChild('snav') set content(content: ElementRef) {
	//     this.snav = content;
	// }

	@ViewChild('snav') snav;

    constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public auth: AuthService, private router:Router, public global: GlobalService) {
	    this.mobileQuery = media.matchMedia('(max-width: 600px)');
	    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
	    this.mobileQuery.addListener(this._mobileQueryListener);

	    if(this.global.device == 'mobile'){
	    	this.menuOpen = false;
	    }
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
	    this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  	screen(width) {
    	return ( width < 700 ) ? 'sm' : 'lg';
	}

	showChangePassword(){
		this.show.changePasswordDialog = true;
	}

	changePassword(){
	    if(this.dataUser.oldPassword == undefined || this.dataUser.newPassword == undefined || this.dataUser.confirmPassword == undefined){
	      this.global.showErrorDialog("Semua field wajib di isi.");
	    }else if(this.dataUser.newPassword != this.dataUser.confirmPassword){
	      this.global.showErrorDialog("Konfirmasi password salah.");
	    }else{
	      this.global.get(`user/detail/` + this.auth.user.userName)
	        .subscribe(detail => {
	          this.auth.user.password     = shajs('sha256').update(this.dataUser.oldPassword).digest('hex');
	          this.auth.user.newPassword  = shajs('sha256').update(this.dataUser.newPassword).digest('hex');
	          this.global.get(`user/change-password/` + this.auth.user.userName + '/' + this.auth.user.password + '/' + this.auth.user.newPassword)
	            .subscribe(result => {
	                this.dataUser = {};
	                this.show.changePasswordDialog = false;
	                this.global.showSuccessDialog("Password berhasil diubah.");
	          });
	      });
	    }
	}

	logOut(){
		this.auth.logout();
		this.router.navigate(['/login']);
		location.reload();
	}

	menuClick(event){
		if(event.itemData.routerLink != undefined){
			this.snav.close();
			if(event.itemData.menuType == 'external-link')
				// window.open("https://" + location.host + "/" + event.itemData.externalAppUrl + event.itemData.routerLink, "_blank");
				window.open(event.itemData.externalAppUrl, "_blank");
			else
				this.router.navigate([event.itemData.routerLink]);
		}
		if(this.mobileQuery.matches){
			if(event.itemData.routerLink != undefined){
				this.menuOpen = false;	
			}
		}

		event.component.expandItem(event.node.key);
	}
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable()
export class AuthGuardService implements CanActivate {
	
  	constructor(private global: GlobalService, private auth: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var currentUrl = state.url.substr(1, 100);
        
        if (this.auth.isLoggedIn){
          var isAuthorized = this.auth.user.menus['e-logistics'][currentUrl];
          if(isAuthorized){
            return true;
          }else{
            if(currentUrl != 'vendor/welcome-page' && !currentUrl.startsWith('driver')){
              this.auth.redirectUrl = state.url;
              this.router.navigateByUrl('/login');
              this.global.showErrorDialog('You have no authorization to access this page.');
              return false;
            }else{
              return true;
            }
          }
        } else {
          if(!currentUrl.startsWith('driver')){
            this.auth.redirectUrl = state.url;
            this.router.navigateByUrl('/login');
            return false;
          }else{
            return true;
          }
        }
    }
}